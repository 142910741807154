﻿import 'regenerator-runtime/runtime';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const APP_ROLE = 'Authentication';

async function getConfig() {
    const response = await fetch('/app-insights.json');
    return await response.json();
}
async function initialize() {

    const config = await getConfig();

    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: config.instrumentationKey
        }
    });

    appInsights.loadAppInsights();

    appInsights.addTelemetryInitializer(telemetry => { telemetry.tags['ai.cloud.role'] = APP_ROLE });
    appInsights.addTelemetryInitializer(telemetry => { telemetry.data['Environment'] = config.environment });

    appInsights.trackPageView();

    // UGLY
    // We're doing this here only because here we have this config file fetched
    if (config.environment) document.body.parentElement.classList.add(`env-${config.environment.toLowerCase()}`);
}

initialize();